import React, { Component } from 'react';
// import { Route } from 'react-router';
import { Route, Routes, useParams } from 'react-router-dom';
import { Layout } from './components/Layout';

import './custom.css'
import { ApplicationPaths, LoginActions, LogoutActions } from './components/api-authorization/ApiAuthorizationConstants';
import { Login } from './components/api-authorization/Login';
import { Logout } from './components/api-authorization/Logout';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { Questions } from './components/questions';
import Home from './components/Home';
import SupportAdminComponent from './components/support/SupportAdminComponent';
import SupportComponent from './components/support/SupportComponent';
import HowToComponent from './components/HowTo';
import { PricingComponent } from './components/Pricing';
import FAQComponent from './components/FAQ';
import { DownloadComponent } from './components/Download';
import { AboutComponent } from './components/About';
import { TermsComponent } from './components/Terms';
import { PrivacyComponent } from './components/Privacy';
import { Companies } from './components/Company/Companies';
import Company from './components/Company/Company';

export default class App extends Component {

  render () {
    return (
      <Layout>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/Support' element={<SupportComponent />} />

          <Route exact path='/Survey-History' element={
              <AuthorizeRoute path='/Survey-History' component={Questions}></AuthorizeRoute>
            } 
          />
          <Route exact path='/Support-Admin/:id' element={
              <AuthorizeRoute path='/Support-Admin/:id' component={SupportAdminComponent}></AuthorizeRoute>
            } 
          />
          <Route exact path='/Support-Admin' element={
              <AuthorizeRoute path='/Support-Admin' component={SupportAdminComponent}></AuthorizeRoute>
            } 
          />
          <Route exact path='/Authorize/Companies' element={
              <AuthorizeRoute path='/Companies' component={Companies}></AuthorizeRoute>
            } 
          />
          <Route exact path='/Authorize/Company' element={
              <AuthorizeRoute path='/Company' 
              component={() => <Company edit={true} />}></AuthorizeRoute>
            } 
          />
          <Route exact path='/HowTo' element={<HowToComponent />} />
          <Route exact path='/FAQ' element={<FAQComponent />} />
          <Route exact path='/Pricing' element={<PricingComponent />} />
          <Route exact path='/About' element={<AboutComponent />} />
          <Route exact path='/Terms' element={<TermsComponent />} />
          <Route exact path='/Privacy' element={<PrivacyComponent />} />
          <Route exact path='/Download-SmartBusiness-Manager' 
            element={<DownloadComponent />} />
          <Route exact path='/Subscribe/:id' element={<GetDownloadComponent />} />
          {/* <Route exact path='/Supplier/:id' element={
            <AuthorizeRoute path='/Supplier/:id' component={GetSupplier}></AuthorizeRoute>
          } />
          <Route exact path='/Supplier/Details/:id' element={
            <AuthorizeRoute path='/Supplier/Details/:id' component={GetSupplierDetails}></AuthorizeRoute>
          } />
          <Route exact path='/Suppliers' element={
            <AuthorizeRoute path='/Suppliers' component={Suppliers}></AuthorizeRoute>
          } /> */}

          <Route exact path={ApplicationPaths.Login} element={<Login action={LoginActions.Login} />} />
          <Route path={ApplicationPaths.LoginFailed} element={<Login action={LoginActions.LoginFailed} />} />
          <Route path={ApplicationPaths.LoginCallback} element={<Login action={LoginActions.LoginCallback} />} />
          <Route path={ApplicationPaths.Profile} element={<Login action={LoginActions.Profile} />} />
          <Route path={ApplicationPaths.Register} element={<Login action={LoginActions.Register} />} />
          <Route path={ApplicationPaths.LogOut} element={<Logout action={LogoutActions.Logout} />} />
          <Route path={ApplicationPaths.LogOutCallback} element={<Logout action={LogoutActions.LogoutCallback} />} />
          <Route path={ApplicationPaths.LoggedOut} element={<Logout action={LogoutActions.LoggedOut} />} />
        </Routes>
      </Layout>
    );
  }
}

function GetDownloadComponent() {
  const { id } = useParams();
  return (<DownloadComponent planId={id}/>);
}

// function GetSupplierDetails() {
//   const { id } = useParams();
//   return (<Supplier _Id={id} edit={true}/>);
// }
