import React, { useState, useEffect } from 'react';
import appConfig from '../AppConfig';
// import { Navigate } from 'react-router-dom';
// import auth from '../auth/auth';
// import '<script src="https://checkout.flutterwave.com/v3.js"></script>';


export const DownloadComponent  = (planId = -1) =>  {
    
    const [email, setEmail]= useState('');
    const [Address, setAddress]= useState('');
    const [Phone, setPhone]= useState('');
    const [Country, setCountry]= useState('');
    const [Business, setBusiness]= useState('');
    const [Zip, setZip]= useState('');
    const [Names, setNames]= useState('');
    const [message, setMessage]= useState('');
    const [redirect, setRedirect]= useState(false);
    const [isLoading, setIsLoading]= useState(false);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.flutterwave.com/v3.js";
        script.async = true;

        document.body.appendChild(script);
    }, []);

    const handleSubmit = (btn) =>{
        if(Names===""){
            setMessage("Names is required!"); return;
        }
        if(Phone===""){
            setMessage("Phone is required!"); return;
        }
        if(Address===""){
            setMessage("Address is required!"); return;
        }
        if(Country===""){
            setMessage("Country is required!"); return;
        }
        if(Zip===""){
            setMessage("Zip is required!"); return;
        }
        if(Business===""){
            setMessage("Business is required!"); return;
        }
        var userData = {
            "email": email,
            "names": Names,
            "address":Address,
            "phone": Phone,
            "zip": Zip,
            "business": Business,
            "Country": Country,
            "tx_ref": "",
            "amount": 0,
            "currency": "NGN",
            "plan": "",
        };
        // "role": role
        // console.log(userData);
        if(planId==-1)
            saveRecord(userData, "download");
        else{
            try {
                (async () => {
                    const plan = appConfig.PricingPlans.find(x=>x.id===Number(planId.planId));
                    // console.log(appConfig.PricingPlans);
                    // console.log(planId.planId);
                    // console.log(plan);
                    const flutObj = {
                        public_key: appConfig.PubFlutter,
                        tx_ref: generateUUID().substring(0, 8),
                        amount: plan.amount,
                        currency: appConfig.CurrencyCode,
                        payment_options: "card, ussd",
                        payment_plan: plan.id,
                        // redirect_url: appConfig.appUrl + "/verify-payment?Amount=" + 
                        //     plan.amount + "&PId=" + plan.id + "&pName=" + plan.name + 
                        //     "&currency=" +appConfig.CurrencyCode,
                        callback: function(payment) {
                            // Send AJAX verification request to backend
                            // verifyTransactionOnBackend(payment.id);
                            
                            const payment1 = {
                                "amount": 54600,
                                "currency": "NGN",
                                "customer": {
                                    "name": "Rose DeWitt Bukater",
                                    "email": "rose@unsinkableship.com",
                                    "phone_number": "08102909304"
                                },
                                "flw_ref": "FLW-MOCK-597ae423f1470309edcb5879e3774bfa",
                                "status": "successful",
                                "tx_ref": "titanic-48981487343MDI0NzMx",
                                "transaction_id": 495000
                            }
                              
                            userData.tx_ref=payment.transaction_id
                            userData.amount=payment.amount
                            userData.currency=payment.currency
                            userData.plan=plan.name
                            saveRecord(userData, 'payment');
                        },
                        onclose: function(incomplete) {
                            if (incomplete || window.verified === false) {
                                document.querySelector("#payment-failed").style.display = 'block';
                            } else {
                                document.querySelector("form").style.display = 'none';
                                if (window.verified == true) {
                                    document.querySelector("#payment-success").style.display = 'block';
                                } else {
                                    document.querySelector("#payment-pending").style.display = 'block';
                                }
                            }
                        },
                        meta: {
                            ...userData
                        },
                        customer: {
                            email: email,
                            phone_number: Phone,
                            name: Names,
                        },
                        customizations: {
                            title: appConfig.AppName + " (" + plan.name + ")",
                            description: "Payment for " + plan.name,
                            logo: appConfig.appUrl + "/logo.png",
                        },
                    }
                    // console.log(flutObj);
                    const modal = window['FlutterwaveCheckout'](flutObj);
                })();
            } catch (error) {
                setMessage(error.toString().replace("TypeError: ", ""));
            }
        }
    }
    const saveRecord=(userData, linkTo)=>{
        try {
            (async () => {
                setIsLoading(true);
                var result = await appConfig.PostData(
                    linkTo, 'POST', JSON.stringify(userData));
                // console.log(result);
                if(result["status"] !== "Success"){
                    if(result["error"] != null || result["error"] !== undefined){
                        setMessage(result["error"])
                    }else{
                        setMessage(result);
                    }
                }else{
                    setMessage(result["message"])
                    setTimeout(()=>{
                        window.location.href = '../' ;
                    }, 5000)
                    setRedirect(true);
                }
                setIsLoading(false);
                return;
            })();
        } catch (error) {
            setMessage(error.toString().replace("TypeError: ", ""));
        }
    }

    function generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    return ( 
        <React.Fragment>
            <div className='row justify-content-center mt-5 mb-5 m-2'>
                <form className='card col-md-4 p-0 border-primary border-2'>
                    <div className='card-header'>
                        <h4 className='text-center'>
                            Download Form
                        </h4>
                    </div>
                    <div className='card-body'>
                        <p>
                            Filling the form bellow to get access to the software.
                            All values are required and must be valid.
                        </p>
                        <div className='form-group'>
                            <label className='control-label lbl'>Names</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className='fa fa-user'></i>
                                </span>
                                <input required className='form-control' type='email' 
                                    placeholder='Enter your Names'
                                    value={Names} autoFocus
                                    onChange={e => setNames(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Email</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">@</span>
                                <input required className='form-control' type='email' 
                                    name='Email' id='Email' placeholder='Enter Email'
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Phone</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className='fa fa-phone'></i>
                                </span>
                                <input required className='form-control' type='text' 
                                    placeholder='Enter Phone' value={Phone}
                                    name='phone'
                                    onChange={e => setPhone(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Zip</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className='far fa-user'></i>
                                </span>
                                <input required className='form-control' type='text' 
                                    placeholder="Enter Your Country's Zip"
                                    value={Zip}
                                    onChange={e => setZip(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Country</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className='fa fa-globe'></i>
                                </span>
                                <input required className='form-control' type='text' 
                                    placeholder='Enter Your Country' value={Country}
                                    name='country'
                                    onChange={e => setCountry(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Address</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className='fa fa-home'></i>
                                </span>
                                <input required className='form-control' type='text' 
                                    placeholder='Enter Address' value={Address}
                                    name='address'
                                    onChange={e => setAddress(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Business Type</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className='fa fa-briefcase'></i>
                                </span>
                                <input required className='form-control' type='text' 
                                    placeholder='Enter Business Type'
                                    value={Business}
                                    onChange={e => setBusiness(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className="d-line">
                                <input type='checkbox' checked required className='form-control-sm' />
                                <span className='text-dark ml-2'> Agree to our term and condition.</span>
                            </div>
                        </div>
                    </div>

                    <div className='form-group text-center card-footer'>
                        {isLoading &&
                            <button 
                                type='button'
                                className='btn btn-primary btn-5'>
                                <i className='fa fa-spinner fa-spin'></i> Submiting...
                            </button>
                        }{!isLoading &&
                            <button 
                                className='btn btn-primary btn-5'
                                onClick={e => {
                                    e.preventDefault();
                                    return handleSubmit(this)
                                }}>
                                    <i className='fa fa-send pe-2'></i>
                                    {planId === -1 && 'Proceed To Download'}
                                    {planId !== -1 && 'Proceed To Pay'}
                            </button>
                        }
                        <div className='text-warning'>
                            {message}
                        </div>
                        
                        <div id="payment-success" hidden>
                            Thank you! Enjoy your awesome cruise.🚢
                        </div>
                        <div id="payment-pending" hidden>
                            Verifying...Setting up your cruise🚢
                        </div>
                        <div><a href='../'>Back To Home?</a></div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}