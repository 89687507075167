import React from 'react';
import { useState } from 'react';
import appConfig from '../AppConfig';

export const SubscribeComponent = () => {
    const [isUpLoading, setisUpLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [msgS, setmsgS] = useState('');
    const [msgF, setmsgF] = useState('');

    const Subscribe = (btn) =>{
        (async () => {
            setmsgF("");
            setmsgS("");
            var answer = window.confirm("Subscribe to our news list?");
            if(answer){
                setisUpLoading(true);
                var result = await appConfig.PostData(`subscribe/` + email, 'POST');
                // console.log(result);
                if(result["Error"] != null || result["Error"] !== undefined){
                    setmsgF(result.Error);
                    setisUpLoading(false);
                }if(result['message'].toString().includes("success")){
                    setmsgS(result['message'])
                }else{
                    setmsgF(result['message'])
                }
                setisUpLoading(false);
            }
        })().then(()=>{setisUpLoading(false);});
    }

    return ( 
        <div className="col-md-12 text-center card-header justify-content-center" id="Subscribe">
            <div className='text-secondary p-4'>
                <div className='card-header'>
                    <h4 className='text-primary'>Subscribe</h4>
                    <hr />
                    <p>Want to be notified of our new updates?</p>
                    <p>
                        Enter your email address in the input filed below to get notified
                        once a new update is available.
                    </p>
                </div>
                <div className='justify-content-center d-flex mb-4 mt-4'>
                    <div animation-type='flash' className='animated-item animated flash shadow bg-light col-md-6 card p-4 mb-2'>
                        <div className='form-group text-center'>
                            <label className='control-label lbl'>Email</label>
                            <input required className='form-control text-center' 
                                type='text' name='Email' id='Email' 
                                placeholder='Enter Your Email Address'
                                onChange={e=>{setEmail(e.target.value)}}
                                />
                        </div>
                        <hr className='hr'/>
                        <div>
                            <p className='text-danger'>{msgF}</p>
                            <p className='text-primary'>{msgS}</p>
                        </div>
                        <div className='form-group'>
                            { 
                                !isUpLoading && <button type='button' className='btn btn-primary btn-5'
                                    onClick={e=>{
                                        Subscribe(e.target)}
                                    }
                                >
                                    <i></i> Subscribe Now
                                </button>
                            }
                            { 
                                isUpLoading && <button disabled
                                    className='btn btn-primary col-md-6'>
                                    <i className='fa fa-spinner fa-spin me-2'></i>
                                    Subscribing...</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 