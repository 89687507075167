import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
// import '../sidebars.css';
import './NavMenu.css';
import { LoginComponent } from './Account/login';
import { RegisterComponent } from './Account/register';
import logo from '../images/logo.png';
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import appConfig from '../AppConfig';

export class NavMenuTop extends Component {

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      isAuthenticated: false,
      userName: null
    };
  }

  componentDidMount(){

    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentDidUpdate(){
    var els = document.getElementsByClassName("dropdown");
    Array.from(els).forEach(element => {
      if(element.getAttribute('toggles') != null){
        element.onclick = ()=>{
          var elId = element.getAttribute('target-att');
          var el = document.getElementById(elId);
          el.classList.toggle('dropdown-menu');      
          console.log(el.classList)
        }
      }
    });
  }

  componentWillUnmount() {
      authService.unsubscribe(this._subscription);
  }

  async populateState() {
      const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
      this.setState({
          isAuthenticated,
          userName: user && user.name
      });
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    const { isAuthenticated, userName } = this.state;
    return (
      <React.Fragment>
        <div className='nav-m box-shadow'>
          <img src={logo} alt="" className='rounded img-header-logo'/>
          <a href='/' className='nav-m-a'>{appConfig.AppName}</a>
          <collapser>
            <li>
              <a className='collapser-a' tag={Link} href="../About">About</a>
            </li>
            <li>
              <a className='collapser-a' tag={Link} href="../#Features">Features</a>
            </li>
            <li>
              <a className='collapser-a' tag={Link} href="../Pricing">Pricing</a>
            </li>
            <li>
              <a className='collapser-a' tag={Link} href="../#FAQ">FAQ</a>
            </li>
            <li>
              <a className='collapser-a' tag={Link} href="../#Support">Support</a>
            </li>
            <LoginMenu>
            </LoginMenu>
          </collapser>
          <toggler className="h5 fa fa-bars sidebar-toggle1 ms-2"
              onClick={
                ()=>{
                  document.getElementById('side-nav').classList.toggle("close");
                }
              }>
          </toggler>
        </div>
      </React.Fragment>
    );
  }
}
