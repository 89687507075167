import React from "react";
import { Component } from "react";

// export const FAQComponent = () => {
export default class FAQComponent extends Component{
    // function ShowHideAccordion(id){
    ShowHideAccordion=(id)=>{
        var ac = document.getElementById(id);
        if(ac === undefined || ac === null)
        return;

        // alert(id)
        // ac.className = ac.classList.toggle("show");
        ac.classList.toggle("show");
        console.log(ac.className);
    }
    render(){
    return ( 
        <div id="FAQ" 
            className="row bg-light shadow justify-content-center d-flex pt-5 pb-5 text-center">
            <h4 className='text-info'>
                <u>Frequently Asked Questions</u>
            </h4>
            <p>
                Below is the most common questions asked by our customers.
            </p>
            <div
                animation-type="fadeInLeft" 
                className="animated-item animated fadeInLeft">
                <div className="accordion row" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading1" 
                            onClick={(e)=>{this.ShowHideAccordion("collapse1")}}>
                            <button className="accordion-button" type="button" >
                                Do I have to pay for updates?
                            </button>
                        </h2>
                        <div id="collapse1" className="accordion-collapse collapse show" 
                            aria-labelledby="heading1" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                No, we give constant updates to our software, add new features and you get it all for FREE!
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading2" 
                            onClick={(e)=>{this.ShowHideAccordion("collapse2")}}>
                            <button className="accordion-button" type="button" >
                                Is the software really secure?
                            </button>
                        </h2>
                        <div id="collapse2" className="accordion-collapse collapse" 
                            aria-labelledby="heading2" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes! Security is our topmost priority and we built 
                                smart business manager from the ground up to make sure your 
                                entire business is super secure from malware. 
                                You don't have anything to worry about.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3" 
                            onClick={(e)=>{this.ShowHideAccordion("collapse3")}}>
                            <button className="accordion-button" type="button" >
                                If I have questions, is there someone I can talk to?
                            </button>
                        </h2>
                        <div id="collapse3" className="accordion-collapse collapse" 
                            aria-labelledby="heading3" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes! We have a first class support team who can 
                                answer your questions 24 hours a day, 7 days a week!
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading4" 
                            onClick={(e)=>{this.ShowHideAccordion("collapse4")}}>
                            <button className="accordion-button" type="button" >
                                If I cancel my SBM account, will I lose my data?
                            </button>
                        </h2>
                        <div id="collapse4" className="accordion-collapse collapse" 
                            aria-labelledby="heading4" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                No! You'll get the option to download all your stock and pricelists as a CSV file.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading5" 
                            onClick={(e)=>{this.ShowHideAccordion("collapse5")}}>
                            <button className="accordion-button" type="button" >
                                Is there a trial period?
                            </button>
                        </h2>
                        <div id="collapse5" className="accordion-collapse collapse " 
                            aria-labelledby="heading5" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                There's a 14 day free trial period. Just enter your name, 
                                email address and you're good to go. We don't ask for your 
                                payment details for trial. Choose your right plan here.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading6" 
                            onClick={(e)=>{this.ShowHideAccordion("collapse6")}}>
                            <button className="accordion-button" type="button" >
                                How can I access Smart Business Manager?
                            </button>
                        </h2>
                        <div id="collapse6" className="accordion-collapse collapse" 
                            aria-labelledby="heading6" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            You can either access it offline by downloading the software 
                            and installing on your computer…
                            Or online by logging into your account.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )};
}