import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
// import '../sidebars.css';
import './NavMenu.css';
import { LoginComponent } from './Account/login';
import { RegisterComponent } from './Account/register';
import logo from '../images/logo.png';
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import appConfig from '../AppConfig';
import { NavMenuTop } from './NavMenu-Top';

export class NavMenu extends Component {

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      isAuthenticated: false,
      userName: null
    };
  }

  componentDidMount(){

    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentDidUpdate(){
    var els = document.getElementsByClassName("dropdown");
    Array.from(els).forEach(element => {
      if(element.getAttribute('toggles') != null){
        element.onclick = ()=>{
          var elId = element.getAttribute('target-att');
          var el = document.getElementById(elId);
          el.classList.toggle('dropdown-menu');      
          console.log(el.classList)
        }
      }
    });
  }

  componentWillUnmount() {
      authService.unsubscribe(this._subscription);
  }

  async populateState() {
      const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
      this.setState({
          isAuthenticated,
          userName: user && user.name
      });
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    const { isAuthenticated, userName } = this.state;
    return (
      <React.Fragment>
        <div className="modal fade modal-bg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog shadow">
                <div className="modal-content border shadow">
                    <div className="modal-header">
                        <h5 className="modal-title text-light" id="exampleModalLabel">
                            ANAKINGS HOMES AND PROPERTIES
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <ul className="nav nav-pills nav-fill">
                            <li className="nav-item">
                                <a className="nav-link active"  id="login-tab" data-bs-toggle="tab" data-bs-target="#login" aria-current="page" href="#">Login</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#"  id="register-tab" data-bs-toggle="tab" data-bs-target="#register">Register</a>
                            </li>
                        </ul>
                        <hr className='hr'/>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                                <LoginComponent />
                            </div>
                            <div className="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                                <RegisterComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <header>
          <nav className='close bg-light'  id='side-nav'>

            <div className="logo-name">
                <div className="logo-image">
                    <img src={logo} alt="" className='rounded bg-light img-logo'/>
                </div>
    
                <span className="logo_name" >{appConfig.AppName}</span>
                <i className="ps-4 h5 fa fa-window-close sidebar-toggle1"
                    onClick={()=>{
                      document.getElementById('side-nav').classList.toggle("close");
                    }}
                ></i>
            </div>
    
            <div className="menu-items">
                <ul className="nav-links ps-0">
                  <li>
                    <a href="../About"  
                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i className="fa fa-window-restore"></i>
                        <span className="link-name">About</span>
                    </a>
                  </li>
                  <li>
                    <a href="../#Features"  
                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i className="fa fa-star"></i>
                        <span className="link-name">Features</span>
                    </a>
                  </li>
                  <li>
                    <a href="../Pricing"  
                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i className="fa fa-cart-plus"></i>
                        <span className="link-name">Pricing</span>
                    </a>
                  </li>
                  <li>
                    <a href="../#Support"  
                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i className="fa fa-envelope"></i>
                        <span className="link-name">Support</span>
                    </a>
                  </li>
                  <li>
                    <a href="../#FAQ"  
                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i className="fa fa-question"></i>
                        <span className="link-name">FAQ</span>
                    </a>
                  </li>
                  <li>
                    <a href="../#Subscribe"  
                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i className="fa fa-bell"></i>
                        <span className="link-name">Subscribe</span>
                    </a>
                  </li>
                  <li>
                    <a href="../HowTo">
                      <i className="fa fa-bookmark"></i>
                      <span className="link-name">How To</span>
                    </a>
                  </li>
                </ul>
                <ul className="nav-links ps-0">
                    {!isAuthenticated && <React.Fragment>
                      <li>
                        <a href={ApplicationPaths.Login}  
                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <i className="fa fa-user"></i>
                            <span className="link-name">Login</span>
                        </a>
                      </li>
                      <li>
                        <a href={ApplicationPaths.Register}>
                            <i className="fa fa-user-plus"></i>
                            <span className="link-name">Register</span>
                        </a>
                      </li></React.Fragment>
                    }
                    {isAuthenticated && <React.Fragment>
                    <li>
                      <a href='/Dashboard'>
                          <i className="fa fa-chart-line"></i>
                          <span className="link-name">Dashboard</span>
                      </a>
                    </li>
                    <li>
                      <a href='/Sales'>
                          <i className="fa fa-bullhorn"></i>
                          <span className="link-name">Sales</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(#Products)" className='dropdown' toggles="dropdown" target-att='drp-1'>
                          <i className="fab fa-product-hunt"></i>                            
                          <span className="link-name">Products</span>
                      </a>
                      <div className="dropdown-menu" id='drp-1'>
                        <a className="dropdown-item" href="/Product">New Product</a>
                        <a className="dropdown-item" href="/Products">View Products</a>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(#Categories)" className='dropdown' toggles="dropdown" target-att='drp-1'>
                          <i className="fa fa-list"></i>                            
                          <span className="link-name">Categories</span>
                      </a>
                      <div className="dropdown-menu" id='drp-1'>
                        <a className="dropdown-item" href="/Category">New Categories</a>
                        <a className="dropdown-item" href="/Categories">View Categories</a>
                        {/* <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a> */}
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(#Shelves)" className='dropdown' toggles="dropdown" target-att='drp-2'>
                          <i className="fa fa-list"></i>                            
                          <span className="link-name">Shelves</span>
                      </a>
                      <div className="dropdown-menu" id='drp-2'>
                        <a className="dropdown-item" href="/Shelf">New Shelf</a>
                        <a className="dropdown-item" href="/Shelves">View Shelves</a>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(#Customers)" className='dropdown' toggles="dropdown" target-att='drp-3'>
                          <i className="fa fa-users"></i>                            
                          <span className="link-name">Customers</span>
                      </a>
                      <div className="dropdown-menu" id='drp-3'>
                        <a className="dropdown-item" href="/Customer">New Customer</a>
                        <a className="dropdown-item" href="/Customers">View Customers</a>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(#Suppliers)" className='dropdown' toggles="dropdown" target-att='drp-4'>
                          <i className="fa fa-users"></i>                            
                          <span className="link-name">Suppliers</span>
                      </a>
                      <div className="dropdown-menu" id='drp-4'>
                        <a className="dropdown-item" href="/Supplier">New Supplier</a>
                        <a className="dropdown-item" href="/Suppliers">View Suppliers</a>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(#Branches)" className='dropdown' toggles="dropdown" target-att='drp-6'>
                          <i className="fa fa-code-branch"></i>                            
                          <span className="link-name">Branches</span>
                      </a>
                      <div className="dropdown-menu" id='drp-6'>
                        <a className="dropdown-item" href="/Branch">New Branch</a>
                        <a className="dropdown-item" href="/Branches">View Branches</a>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(#PaymentAccounts)" className='dropdown' toggles="dropdown" target-att='drp-7'>
                          <i className="fa fa-bank"></i>                            
                          <span className="link-name">Payment Accounts</span>
                      </a>
                      <div className="dropdown-menu" id='drp-7'>
                        <a className="dropdown-item" href="/PaymentAccount">New Payment Account</a>
                        <a className="dropdown-item" href="/PaymentAccounts">View Payment Accounts</a>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(#Expenses)" className='dropdown' toggles="dropdown" target-att='drp-8'>
                          <i className="fa fa-arrow-right"></i>                            
                          <span className="link-name">Expenses</span>
                      </a>
                      <div className="dropdown-menu" id='drp-8'>
                        <a className="dropdown-item" href="/Expense">New Expense</a>
                        <a className="dropdown-item" href="/Expenses">View Expenses</a>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(#ExpenseTypes)" className='dropdown' toggles="dropdown" target-att='drp-9'>
                          <i className="fa fa-list"></i>                            
                          <span className="link-name">Expense Types</span>
                      </a>
                      <div className="dropdown-menu" id='drp-9'>
                        <a className="dropdown-item" href="/ExpenseType">New Expense Type</a>
                        <a className="dropdown-item" href="/ExpenseTypes">View Expense Types</a>
                      </div>
                    </li>
                  </React.Fragment>}
                </ul>
                
                <ul className="logout-mode">
                    {isAuthenticated && <React.Fragment>
                      <li>
                        <a href={ApplicationPaths.Profile}>
                            <i className="fa fa-info-circle"></i>
                            <span className="link-name">Profile</span>
                        </a>
                      </li>
                      <li>
                        <a href={ApplicationPaths.LogOut}>
                            <i className="fa fa-key"></i>
                            <span className="link-name">Logout</span>
                        </a>
                      </li>
                    </React.Fragment>}
                </ul>
            </div>
          </nav>
        </header>
        <NavMenuTop></NavMenuTop>
      </React.Fragment>
    );
  }
}
