import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { FooterComponent } from './FooterComponent';
import { ToastContainer } from 'react-toastify';

export class Layout extends Component {
  static displayName = Layout.name;

  componentDidMount(){
    var el = document.getElementById('main-container');
    el.onclick=(()=>{
      var sideNav = document.getElementById('side-nav');
        if(sideNav !== undefined &&sideNav !== null){
          if(!sideNav.classList.contains("close"))
          sideNav.classList.toggle("close");
        }
      })
      
    window.onload = () => {
      var loaderEl = document.getElementById("loaderDiv");
      //loaderEl.style.display = 'none';
      loaderEl.classList = 'hidden';
  }
  }

  render () {
    return (
      <div id='main-div' >
        <ToastContainer />
        <div id="loaderDiv" className="d-flex loader justify-content-center">
            <div className="loading text-center">
                {/* <span className="text-primary fa spinner fa-spin fa-4x"></span> */}
            </div>
        </div>
        <NavMenu />
        <div id="main-container" className='pt-5' style={{
            minHeight: '85vh',
          }}>
          {this.props.children}
        </div>
        <FooterComponent></FooterComponent>
        <div className='bg-dark' hidden>
          <div className='container p-2'>
            <p className='text-white text-center'>
              RAYZER BUSINESS SOLUTION  © 2022. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
