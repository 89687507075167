import React, { useState, useEffect, useRef } from "react";
import CustomAlert from "../custom/custom_alert";
import AppConfig from "../../AppConfig";
import { toast } from "react-toastify";
import { LoaderFull } from "../LoaderFull";

const Company = (props) => {
  const [loading, setLoading] = useState(false);
  const [upLoading, setUpLoading] = useState(false);
  const [company, setCompany] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    status: true, // You can set the default status here
  });
  const [msg, setMsg] = useState("");
  const [msgColor, setMsgColor] = useState(AppConfig.messageColors.success);
  const formRef = useRef(null);
  const edit = props.edit === true;

  useEffect(() => {
    populateData();
  }, []);

  const populateData = async () => {
    if (props._Id === undefined || props._Id === null) return;
    setLoading(true);
    var result = await AppConfig.PostData("companies/" + props._Id, "GET");
    setLoading(false);

    if (result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (result["data"] === undefined) {
      toast.warn("No result found");
    } else {
      setCompany(result["data"]);
    }
  };

  const submitData = async () => {
    // if (AppConfig.getAccessRole() !== "admin") return;
    // setMsgColor(AppConfig.messageColors.warning);
    if (company.name === "" || !company.name) {
      setMsg("Name is required!");
      return;
    }
    setUpLoading(true);

    var url =
      "companies" +
      (props._Id === undefined || props._Id === null
        ? ""
        : "/" + props._Id);
    var method =
      props._Id === undefined || props._Id === null ? "POST" : "PUT";

    const body = JSON.stringify({
      name: company.name,
      phone: company.phone,
      email: company.email,
      address: company.address,
    });

    var result = await AppConfig.PostData(url, method, body);
    console.log(result);
    if (result["exception"] !== undefined || result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (
      result["result"] === "success" ||
      result["result"] === true ||
      result["data"] !== undefined
    ) {
      toast(result["message"]);
      if (props._Id === undefined) {
        var newObj = { ...company };
        newObj.name = "";
        newObj.phone = "";
        newObj.email = "";
        newObj.address = "";
        setCompany(newObj);
      }
    } else {
      toast(result["message"]);
    }
    setUpLoading(false);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setCompany({
  //     ...company,
  //     [name]: value,
  //   });
  // };

  const handleDetailsChange = (input, e) => {
    var newObj = { ...company };
    newObj[input] = e.target.value;
    setCompany(newObj);
  };

  const renderData = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitData();
        }}
      >
        <fieldset
          id="fieldset"
          className="p-4 container"
          ref={formRef}
          disabled={!edit}
        >
          <div className="row">
            <div className="form-group">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                aria-label="Name"
                aria-describedby="basic-addon1"
                value={company.name}
                onChange={(e) => handleDetailsChange("name", e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Phone"
                aria-label="Phone"
                aria-describedby="basic-addon1"
                value={company.phone}
                onChange={(e) => handleDetailsChange("phone", e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                value={company.email}
                onChange={(e) => handleDetailsChange("email", e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                aria-label="Address"
                aria-describedby="basic-addon1"
                value={company.address}
                onChange={(e) => handleDetailsChange("address", e)}
              />
            </div>
            {!edit && props._Id !== undefined && (
              <div className="row">
                <div className="col-md-6 p-2">
                  <label className="form-label">Active</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        {company.status ? (
                          <i className="fa fa-check text-success"></i>
                        ) : (
                          <i className="fa fa-close text-danger"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </fieldset>
        {msg !== "" && (
          <CustomAlert
            msg={msg}
            type={msgColor}
            Remove={() => {
              setMsg("");
            }}
          />
        )}
        <div className="card-footer p-4">
          {!upLoading &&
            ((props._Id !== null && edit === true) || props._Id === undefined) && (
              <button className="btn btn-success" type="submit">
                <i className="fa fa-save me-2"></i>
                {props._Id === undefined && <span>Save Changes</span>}
                {props._Id !== undefined && <span>Update Item</span>}
              </button>
            )}
          {upLoading && (
            <button disabled className="btn btn-primary">
              <i className="fa fa-spinner fa-spin me-2"></i>
              Saving Changes...
            </button>
          )}
          <div className="mt-2 pt-2 border-top">
            {props._Id != null && edit !== true && (
              <a
                href={"authorize/company/edit/" + props._Id}
                className="btn btn-sm btn-primary rounded m-2"
              >
                <i className="fa fa-edit me-2"></i>
                Edit Company
              </a>
            )}
            {props._Id !== undefined && (
              <a
                href="authorize/company"
                className="btn btn-sm btn-primary rounded m-2"
              >
                <i className="fa fa-plus me-2"></i>
                New Company
              </a>
            )}
            <a
              href="authorize/companies"
              className="btn btn-sm btn-primary rounded m-2"
            >
              <i className="fa fa-close me-2"></i>
              Back to Companies
            </a>
          </div>
        </div>
      </form>
    );
  };

  let contents = loading ? <LoaderFull /> : renderData();

  return (
    <div>
      <div
        className="row justify-content-center"
        aria-labelledby="tabelLabel"
      >
        <div className="details-box col-md-6 card shadow p-0">
          <h4 className="card-header text-warning">
            <div className="w-100 d-flex justify-content-end gap-2">
              <label>
                <a href="authorize/companies" className="btn btn-sm btn-primary">
                  <i className="fa fa-close"></i>
                </a>
              </label>
            </div>
            <div>
              {props._Id === undefined
                ? "Add New Company"
                : edit === true
                ? "Update Company"
                : "Company Details"}
            </div>
          </h4>
          <div className="p-0">
            {(edit === true || props._Id === undefined || props._Id === null) && (
              <p className="p-4">Enter company details below.</p>
            )}
            <p className="border-bottom"></p>
            {contents}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
