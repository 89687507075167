import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import appConfig from "../AppConfig";
import { Loader } from "./loading";
import CustomAlert from "./custom/custom_alert";

export class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      loading: true,
      pageNumber: 0,
      countPerPage: 15,
      sort_by: "name",
      sort_by_order: 1,
      SearchValue: "",
      step: 0,
      _id: this.props._id,
      loadingMain: false,
      userId: "",
      msg: "",
    };
  }

  componentDidMount() {
    this.populateQuestionsData();
  }

  async populateQuestionsData() {
    this.setState({ loadingMain: true });
    var url =
      `survey?` +
      new URLSearchParams({
        search_string: this.state.SearchValue,
      });

    var result = await appConfig.GetData(url, "GET", true);
    //  console.log(result)
    if (result["error"] !== undefined) {
      this.setState({
        msg: "Unable to load Survey. Message: " + result["status"],
      });
      this.setState({ msgColor: appConfig.messageColors.failure });
    } else {
      // this.setState({msg: "Expenses loaded successfully."});
      // this.setState({msgColor: appConfig.messageColors.success});
      if (result.length === 0) {
        this.setState({ msg: "No result found" });
      }
      var newQuestions = [];
      result.forEach((prop) => {
        var item = prop;
        item.StatusClass = this.getStatusClass(prop);
        item.msg = "";
        newQuestions.push(item);
        // console.log(item);
      });
      this.setState({
        questions: newQuestions,
      });
    }
    this.setState({
      loadingMain: false,
    });
  }

  pagesVisited = () => {
    return this.state.pageNumber * this.state.countPerPage;
  };
  pageCount = () => {
    return Math.ceil(this.state.expenses.length / this.state.countPerPage);
  };

  changePage = ({ selected }) => {
    this.setState({ pageNumber: selected });
  };

  displayItems = () => {
    return this.state.questions
      .slice(this.pagesVisited(), this.pagesVisited() + this.state.countPerPage)
      .map((question) => {
        return (
          <div className="">
            <div className="card-header">
              <h6 className="col-9">{question.title}</h6>
              <div className="col-3">
                <a
                  href="#Delete"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.deleteQuestion(question.id, question.title);
                  }}
                  className="btn-sm btn-danger float-end"
                >
                  <span className="fa fa-trash"></span>
                </a>
                <a
                  href={"/Question/" + question.id}
                  hidden
                  className="btn-sm btn-primary me-2 float-end"
                >
                  <span className="fa fa-edit"></span>
                </a>
                <a
                  href={"/Question/Details/" + question.id}
                  hidden
                  className="btn-sm btn-success me-2 float-end"
                >
                  <span className="fa fa-eye"></span>
                </a>
              </div>
            </div>
            <div className="lst shadow p-2">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Count: </label>
                    <p className="description">{question.count}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Created On: </label>
                    <p className="description">{question.created_on}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
  };

  async deleteQuestion(_id, _title) {
    var answer = window.confirm("Delete " + _title + "?");
    if (answer) {
      this.setState({ loading: true });
      var result = await appConfig.PostData(`survey?id=` + _id, "DELETE");
      if (result["status"] !== "Success") {
        this.setState({
          msg: appConfig.messages.failure + " Message: " + result["status"],
        });
        this.setState({ msgColor: appConfig.messageColors.failure });
      } else {
        this.setState({ msg: appConfig.messages.success });
        this.setState({ msgColor: appConfig.messageColors.success });
        var items = this.state.questions.filter((x) => {
          return x.id !== _id;
        });
        this.setState({ questions: items });
      }
      this.setState({
        loading: false,
      });
    }
  }

  clearExpenseMsg(_id) {
    const { questions } = this.state;
    questions.forEach((prop) => {
      if (prop.id === _id) {
        prop.msg = "";
        return;
      }
    });
    this.setState({ questions });
  }

  setSearchValue(val) {
    this.setState({ SearchValue: val });
  }

  setSortBy(val) {
    this.setState({ sort_by: val });
    const { questions } = this.state;
    questions.sort(appConfig.dynamicSort(val, this.state.sort_by_order));
  }

  setSortByOrder(val) {
    this.setState({ sort_by_order: val });
    const { questions } = this.state;
    questions.sort(appConfig.dynamicSort(this.state.sort_by, val));
  }

  render() {
    return (
      <div className="col-md-12 p-4">
        <div className="details-box shadow m-2 p-2">
          <a href="/Expense" className="float-end" hidden>
            Add New Expense
          </a>
          <div className="card-body">
            <div className="form-group">
              {this.state.msg !== "" && (
                <CustomAlert
                  msg={this.state.msg}
                  color={this.state.msgColor}
                  Remove={() => {
                    this.setState({ msg: "" });
                  }}
                />
              )}
            </div>
            <div className="card-header p-2 mt-2">
              <h5 className="text-primary">Available Surveys</h5>
              <p>Below are the list of available survey.</p>
            </div>
            <div className="text-start mt-2">
              <div className="row">
                <div className="form-group col-md-4 float-start mb-2">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by keywords"
                      value={this.state.SearchValue}
                      onChange={(e) => {
                        this.setSearchValue(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          // Cancel the default action, if needed
                          e.preventDefault();
                          // Trigger the button element with a click
                          this.populateQuestionsData();
                        }
                      }}
                    />
                    <span
                      className="input-group-text btn btn-primary"
                      onClick={(e) => {
                        this.populateQuestionsData();
                      }}
                    >
                      <i className=" fa fa-search"></i>
                    </span>
                  </div>
                </div>
                <div className="col-md-8 text-end pe-4">
                  <label className="control-label mw-150 pe-2">Sort By:</label>
                  <select
                    className="mw-150"
                    onChange={(e) => this.setSortBy(e.target.value)}
                  >
                    <option value="title" selected>
                      Title
                    </option>
                    <option value="createdOn">Creation Date</option>
                  </select>
                  <select
                    className="mw-150"
                    onChange={(e) => this.setSortByOrder(e.target.value)}
                  >
                    <option value="1" selected>
                      Asc
                    </option>
                    <option value="-1">Desc</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="">
              {this.state.loadingMain && <Loader />}
              {this.displayItems()}
              <hr />
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={this.pageCount()}
                onPageChange={this.changePage}
                previousLinkClassName={"btn btn-sm btn-primary"}
                nextLinkClassName={"btn btn-sm btn-primary"}
                activeClassName={"btn btn-sm btn-primary"}
                disabledClassName={"btn btn-sm disabled"}
              />
            </div>
          </div>
          <div className="text-start" hidden>
            <a href="/Expense" className="">
              Add New Expense
            </a>
          </div>
        </div>
      </div>
    );
  }
}
