import React from "react";

export const PricingComponent = () => {

    return ( 
        <div id="Pricing" 
            className="row bg-light shadow justify-content-center d-flex pt-5 pb-5 text-center">
            <h4 className='text-info'>
                <u>Pricing Plans</u>
            </h4>
            <p>
                Below is our current Pricing Plans
            </p>
            <div
                animation-type="fadeInLeft" 
                className="animated-item animated col-md-4 fadeInLeft">
                <div className="pricing-card">
                    <h4>
                        <b>
                            Basic Plan <br />
                        </b><span className="price">NGN150,000</span> /  <small className="text-secondary">Year</small>
                    </h4>
                    <hr className="bg-primary" />
                    <p className='text-primary'>Software Features</p>
                    <hr className="bg-primary" />
                    <ul className="text-left">
                        <li className="mb-2"><i className="fas fa-check"></i> <b>1 User</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Unlimited Transactions</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>24/7 Tech Support</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Backup/Restore</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Invoice & Receipts</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Free Updates</b></li>
                    </ul>
                    <div className="pt-4">
                        <a href="../Subscribe/1" className="btn btn-primary border border-4 border-white">Pay Now
                        </a>
                    </div>
                </div>
            </div>
            <div
                animation-type="fadeInLeft" 
                className="animated-item animated col-md-4 fadeInRight">
                <div className="pricing-card">
                    <h4>
                        <b>
                            Pro Plan <br />
                        </b><span className="price">NGN200,000</span> /  <small className="text-secondary">Year</small>
                    </h4>
                    <hr className="bg-primary" />
                    <p className='text-primary'>Software Features</p>
                    <hr className="bg-primary" />
                    <ul className="text-left">
                        <li className="mb-2"><i className="fas fa-check"></i> <b>3 User</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Unlimited Transactions</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>24/7 Tech Support</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Backup/Restore</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Invoice & Receipts</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Free Updates</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>User Loyalty Program</b></li>
                    </ul>
                    <div className="pt-4">
                        <a href="../Subscribe/2" className="btn btn-primary border border-4 border-white">Pay Now
                        </a>
                    </div>
                </div>
            </div>
            <div
                animation-type="fadeInLeft" 
                className="animated-item animated col-md-4 fadeInLeft">
                <div className="pricing-card">
                    <h4>
                        <b>
                            Premium Plan <br />
                        </b><span className="price">NGN250,000</span> /  <small className="text-secondary">Year</small>
                    </h4>
                    <hr className="bg-primary" />
                    <p className='text-primary'>Software Features</p>
                    <hr className="bg-primary" />
                    <ul className="text-left">
                        <li className="mb-2"><i className="fas fa-check"></i> <b>1 User</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Unlimited Transactions</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>24/7 Tech Support</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Backup/Restore</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Invoice & Receipts</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>Free Updates</b></li>
                        <li className="mb-2"><i className="fas fa-check"></i> <b>User Loyalty Program</b></li>
                    </ul>
                    <div className="pt-4">
                        <a href="../Subscribe/3" className="btn btn-primary border border-4 border-white">Pay Now
                        </a>
                    </div>
                </div>
            </div>
        </div>
     );
}