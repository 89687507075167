// import property1 from '../images/slide-1.png';
// import property2 from '../images/slide-2.png';
// import property3 from '../images/slide-3.png';
// import property4 from '../images/slide-4.png';
// import download1 from '../images/Download-Free.png';
// import download2 from '../images/download-now-red.png';
import download3 from '../images/download.png';
// import sqlConStringVideo from '../videos/Sql Connection String.mp4';
// import installVideo from '../videos/How to install.mp4';
import React, { Component } from 'react';
import appConfig from '../AppConfig';


export default class HowToComponent extends Component {
    
    state = { 
        propertyStyle: {
            'width': '100%',
            'maxWidth': '100%',
            'pointer-events': 'none',
        },
        Carousel: {
            'overflow': 'hidden',
        }, carouselIndex: 0,
    } 

    componentDidMount(){
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({carouselIndex: selectedIndex});
    };

    render() { 
        return (
            <div className='pt-4 pb-2 container'>
                <div animation-type='fadeInLeft' 
                    className='animated-item animated fadeInLeft row justify-content-center'>
                    {/* <video src={sqlConStringVideo} 
                        className="w-75 m-5">

                    </video> */}
                    <h4 className='text-center'>
                        How to install {appConfig.AppName}
                    </h4>
                    <video className='video' controls>
                        <source src="../videos/How to install.mp4" type="video/mp4" />
                        <source src="movie.ogg" type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>
                    <div className='mt-5 row justify-content-center text-center'>
                        <h4>
                            <a href="https://www.microsoft.com/en-gb/sql-server/sql-server-downloads">
                                Download Microsoft Sql Server
                            </a>
                        </h4>
                        <p className='large'>Sql Server Express edition is recomended</p>
                        <h4>
                            <a href="https://www.apachefriends.org/download.html">
                                Download Xamp Server
                            </a>
                        </h4>
                        <p className='large'>If you are using windows 7 kindly install .net framework</p>
                        <h4>
                            <a href="https://dotnet.microsoft.com/en-us/download/dotnet-framework/net471">
                                .Net Framework 4.7.1
                            </a>
                        </h4>
                        <p className='large'>{"Download "+appConfig.AppName+" Now"}</p>
                        <h4>
                            
                        <div className='text-center w-100 justify-content-center'>
                            <a href="../Software/Smart Business Manager.zip"
                                className=''>
                                <img src={download3} className="download-btn"
                                    alt={"Download "+appConfig.AppName+" Now"} />
                            </a>
                        </div>
                        </h4>
                    </div>
                    <h4 className='text-center'>
                        How to set database connection string 
                        for {appConfig.AppName}
                    </h4>
                    <video className='video' controls>
                        <source src="../videos/Sql Connection String.mp4" type="video/mp4" />
                        <source src="movie.ogg" type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>

                </div>
            </div>
        );
    }
}

