export const ApplicationName = 'Point_Of_Sales';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'Logout-callback',
  Logout: 'Logout',
  LoggedOut: 'Logged-out'
};

export const LoginActions = {
  Login: 'Login',
  LoginCallback: 'Login-callback',
  LoginFailed: 'Login-failed',
  Profile: 'Profile',
  Register: 'Register'
};

const prefix = '/Account';

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  Register: `${prefix}/${LoginActions.Register}`,
  Profile: `${prefix}/${LoginActions.Profile}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: '/Account/Register',
  IdentityManagePath: '/Account/Manage'
};
