import React from "react";

export const CallToActionComponent = () => {

    return ( 
    <div
        animation-type="fadeInLeft" 
        className="animated-item animated fadeInLeft  col-md-12 m-3 text-center">
        <a href="../Pricing"
            className='animated flash btn btn-secondary m-btn'>
                Buy now
        </a>
        <a href="../Download-SmartBusiness-Manager"
            className='animated flash btn btn-primary m-btn'>
                Free trial
        </a>
    </div>
     );
}