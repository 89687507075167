//import logo from '../logo.png';
import logo from '../images/logo.png';
import React, { useEffect, useState } from 'react';
import { LoginMenu } from './api-authorization/LoginMenu';
import appConfig from '../AppConfig';
// import TawkTo from 'tawkto-react';

export const FooterComponent = () => {
    useEffect(() => {
        //window.addEventListener('load', HandleLoad);
        // var tawk = new TawkTo('5faa95ad8e1c140c2abca796', 'default')
        // // var tawk = new TawkTo('', 'default')

        // tawk.onStatusChange((status) => 
        // {
        //     // console.log(status)
        // })
    }, []);
    
    return (
        <div className="footer">
            <div className="row m-0 justify-content-center">
                <div className="col-md-12 mb-2 pt-4 ps-4">
                    <div className='d-flex'>
                        <a className='p-2' href='/'>
                            <img src={logo} alt="" 
                                className='footer-img img-thumbnail rounded'/>
                        </a><span className='h5 text-secondary footer-title'>{appConfig.AppName}</span>
                    </div>
                    <p className='ps-2 text-light' hidden>
                        Anakings provides a platform to market lands and properties in Africa, USA, Canada, Europe and Australia.
                    </p>
                    <p className='ps-2 text-secondary' hidden>
                        <h6 className='text-info'>Head Office Address:</h6>
                        6815 Riverdale road, Riverdale, An Maryland 20737 USA
                    </p>
                    <p className='ps-2 text-secondary' hidden>
                        <h6 className='text-info'>Branch Office Address:</h6>
                        No 15 Nnobi road, along Awka-Etiti rd, Nnobi, Anambra State, Nigeria
                    </p>
                    <a className='' href='/' hidden>
                        <p className='text-secondary h4 text-center'>
                            {appConfig.AppName}
                        </p>
                    </a>
                </div>
                <hr className='d-block d-md-none bg-secondary' />
                <div className="row text-center pt-4 ps-4">
                    <div className="row text-start">
                        <h5 className="text-secondary">Useful Links</h5>
                        <ul className='footer-li'>
                            <li><a className='collapser-a' href='/'>Home</a></li>
                            <li><a className='collapser-a' href='/About'>About</a></li>
                            <li><a className='collapser-a' href='/Support'>Support</a></li>
                            <li>
                            <a className='collapser-a' href="../#Features">Features</a>
                            </li>
                            <li>
                            <a className='collapser-a' href="../Pricing">Pricing</a>
                            </li>
                            <li>
                            <a className='collapser-a' href="../FAQ">FAQ</a>
                            </li>
                            <li>
                            <a className='collapser-a' href="../HowTo">How To</a>
                            </li>
                        </ul>
                    </div>
                    <div className="row text-start">
                        <ul className='footer-li'>
                            <LoginMenu>
                            </LoginMenu>
                        </ul>
                    </div>
                    <div className="row col-md-12 text-start">
                        <h5 className="text-secondary">Contacts</h5>
                        <p className='text-secondary col-md-4'>
                            <h6 className='text-info'>Email:</h6>
                            <a className='text-secondary' 
                                href='mailto:support@{appConfig.appDomain}'>
                                support@{appConfig.appDomain}
                            </a>
                        </p>
                        <p className='text-secondary col-md-4' href='/' hidden>
                            <h6 className='text-info'>Tel (USA):</h6>
                            <a className='text-secondary' href='tel:443-477-1671'>443-477-1671</a>
                        </p>
                        <p className='text-secondary col-md-4' href='/'>
                            <h6 className='text-info'>Tel (NG):</h6>
                            <a className='text-secondary' href='tel:+234-703-669-1401'>(+234)-703-669-1401</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-center text-secondary border-top">
                <p class="text-light pt-5">
                    <a class="" rel="sponsored" 
                        href="../terms" 
                        target="_blank">Terms & Conditions</a> | 
                    <a class="" rel="sponsored" 
                        href="../privacy" 
                        target="_blank">Privacy Policy</a>
                </p>
                <p>Copyright RAYZER BUSINESS Technologies  © 2022. All Rights Reserved.</p>
                <p class="dev">
                    Designed by
                    <a class="dev" rel="sponsored" href="https://github.com/Chudisoft/" target="_blank">Chudisoft</a>
                </p>
            </div>
        </div>
    );
}