import React from "react";
import appConfig from "../AppConfig";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const ReviewsComponent = () => {

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500,
        rows: 1,
        slidesPerRow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear"
      };
      const settingsMobile = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear"
      };
    return ( 
        <div id="Reviews" 
            className="row bg-light shadow justify-content-center d-flex pt-5 pb-5 text-center">
            <h4 className='text-info'>
                <u>Customer Reviews</u>
            </h4>
            <p>
                Want to hear what Other Business People Like Yourself 
                have to say about Smart Business Manager?
            </p>
            <div animation-type='bounce'  className="animated-item animated bounce ">
                <div className='d-sm-block d-md-none'>
                    <Slider {...settingsMobile} className="justify-content-center">
                        <div className="card p-4" slickerindex={1}>
                            <p >
                                <i className="fas fa-quote-left"></i>
                                One thing I love most is the swift sales page and 
                                the numerous reports, I was able to start and complete 
                                a sale within 5 seconds.
                                <i className="fas fa-quote-right"></i>
                            </p>
                            <div className="text-center pt-3">
                                {/* <img src="assets/images/team-2.jpg" alt="team" className="avatar avatar-sm mr-3" /> */}
                                <i className="fa fa-user fa-2x text-secondary"></i>
                                <h6 className="mb-0">Susanne Pitts</h6>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                </ul>
                                <span className="font-weight-bold small">5.0 <span className="font-weight-lighter">Out of 5</span></span>
                            </div>
                        </div>
                        <div className="card p-4" slickerindex={2}>
                            <p >
                            <i className="fas fa-quote-left"></i>
                            {appConfig.AppName}'s software generates very solid returns. 
                            It fits well for discovering a long-term investment liabilities.
                            <i className="fas fa-quote-right"></i>
                            </p>
                            <div className="text-center pt-3">
                                {/* <img src="assets/images/team-2.jpg" alt="team" className="avatar avatar-sm mr-3" /> */}
                                <i className="fa fa-user fa-2x text-secondary"></i>
                                <h6 className="mb-0">Susanne Pitts</h6>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                </ul>
                                <span className="font-weight-bold small">5.0 <span className="font-weight-lighter">Out of 5</span></span>
                            </div>
                        </div>
                        <div className="card p-4" slickerindex={3}>
                            <p >
                                <i className="fas fa-quote-left"></i>
                                {appConfig.AppName} is of special importance 
                                to me as it has helped me create a sustainable 
                                investment with success.
                                <i className="fas fa-quote-right"></i>
                            </p>
                            <div className="text-center pt-3">
                                {/* <img src="assets/images/team-2.jpg" alt="team" className="avatar avatar-sm mr-3" /> */}
                                <i className="fa fa-user fa-2x text-secondary"></i>
                                <h6 className="mb-0">Susanne Pitts</h6>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                </ul>
                                <span className="font-weight-bold small">5.0 <span className="font-weight-lighter">Out of 5</span></span>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className='d-none d-md-block'>
                    <Slider {...settings}>
                        <div className="col-md-6 card p-4" slickerindex={1}>
                            <p >
                                <i className="fas fa-quote-left"></i>
                                One thing I love most is the swift sales page and 
                                the numerous reports, I was able to start and complete 
                                a sale within 5 seconds.
                                <i className="fas fa-quote-right"></i>
                            </p>
                            <div className="text-center pt-3">
                                {/* <img src="assets/images/team-2.jpg" alt="team" className="avatar avatar-sm mr-3" /> */}
                                <i className="fa fa-user fa-2x text-secondary"></i>
                                <h6 className="mb-0">Susanne Pitts</h6>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                </ul>
                                <span className="font-weight-bold small">5.0 <span className="font-weight-lighter">Out of 5</span></span>
                            </div>
                        </div>
                        <div className="col-md-6 card p-4" slickerindex={2}>
                            <p >
                            <i className="fas fa-quote-left"></i>
                            {appConfig.AppName}'s software generates very solid returns. 
                            It fits well for discovering a long-term investment liabilities.
                            <i className="fas fa-quote-right"></i>
                            </p>
                            <div className="text-center pt-3">
                                {/* <img src="assets/images/team-2.jpg" alt="team" className="avatar avatar-sm mr-3" /> */}
                                <i className="fa fa-user fa-2x text-secondary"></i>
                                <h6 className="mb-0">Susanne Pitts</h6>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                </ul>
                                <span className="font-weight-bold small">5.0 <span className="font-weight-lighter">Out of 5</span></span>
                            </div>
                        </div>
                        <div className="col-md-6 card p-4" slickerindex={3}>
                            <p >
                                <i className="fas fa-quote-left"></i>
                                {appConfig.AppName} is of special importance 
                                to me as it has helped me create a sustainable 
                                investment with success.
                                <i className="fas fa-quote-right"></i>
                            </p>
                            <div className="text-center pt-3">
                                {/* <img src="assets/images/team-2.jpg" alt="team" className="avatar avatar-sm mr-3" /> */}
                                <i className="fa fa-user fa-2x text-secondary"></i>
                                <h6 className="mb-0">Susanne Pitts</h6>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                    <li className="list-inline-item mr-0"><span className="icon icon-xs font-small text-warning"><i className="fas fa-star ratting-color"></i></span>
                                    </li>
                                </ul>
                                <span className="font-weight-bold small">5.0 <span className="font-weight-lighter">Out of 5</span></span>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
     );
}