import React, { Component } from 'react';

export class Loader extends Component {

  render() {
    return (
      <div className='text-center'>
        <h3>Please Wait</h3>
        <p><i className='h3 fa fa-spinner fa-spin'></i></p>
      </div>
    );
  }
}
