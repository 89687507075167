import happyCustomer from '../images/istockphoto-1184479182-612x612.jpg';
import download1 from '../images/Download-Free.png';
// import download2 from '../images/download-now-red.png';
import download3 from '../images/download.png';
// import softwareDownload from '../images/download.png';
// import sqlConStringVideo from '../videos/Sql Connection String.mp4';
// import softwareDownload from '../software/Smart Business Manager.zip';
import React, { Component } from 'react';

import { Row } from 'react-bootstrap';
import SupportComponent from './support/SupportComponent';
import { SubscribeComponent } from './SubscribeComponent';
import { PricingComponent } from './Pricing';
import appConfig from '../AppConfig';
// import { FAQComponent } from './FAQ';
import  FAQComponent  from './FAQ';
import { ReviewsComponent } from './Reviews';
import SoftwareCarosel from './SoftwareCarousel';
import { CallToActionComponent } from './CallToAction';
// import MultipleRows from './slick-component';

class Home extends Component {
    
    state = { 
        propertyStyle: {
            'width': '100%',
            'maxWidth': '100%',
            'pointer-events': 'none',
        },
    } 

    componentDidMount(){
    }

    render() { 
        return (
            <div>
                <div className='pt-4 pb-2'>
                    <Row animation-type='fadeInLeft' className='animated-item animated fadeInLeft'>
                        <div className='col-md-6 text-center'>
                            <SoftwareCarosel></SoftwareCarosel>
                        </div>
                        <div className='col-md-6 text-center bg-primary-mobile p-4'>
                            {/* <a href="software/Smart Business Manager.msi"  */}
                            {/* <a href="../Software/Smart Business Manager.zip" */}
                            <h5 className='text-light-m'>
                                Struggling with managing your stock & tracking your business finances?
                            </h5>
                            <h3 className='text-warning'>
                                Meet Smart Business Manager.
                            </h3>
                            <p className='text-white-m'>
                                The NEW & SIMPLE tool for business owners to save time, 
                                track finances, and run their business all in one place!
                            </p>
                        </div>
                    </Row>
                    <div className='justify-content-center row' id='Features'>
                        <div className='col-md-6 row justify-content-center text-center'>
                            <img src={happyCustomer} className="card rounded w-75 p-2" alt="" />
                        </div>
                        <CallToActionComponent></CallToActionComponent>
                        <div className='col-md-12 bg-primary p-4 m-0'>
                            <h4 className='text-center'>
                                So, Why Use Software To Run Your Business?
                            </h4>
                            <p>
                                If you are like most business owners, then you have one or 
                                more of the following concerns:
                            </p>
                            <div
                                animation-type="fadeInLeft" 
                                className="animated-item animated fadeInLeft">
                                <div className="text-light">
                                    <ul className="text-left">
                                        <li className="mb-2">
                                            <i className="fas fa-check text-warning pr-2"></i> 
                                            <b>
                                                You're making money everyday, but you can't categorically state how much you're making - and at the end of the day, you don't even know where your money is going…
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-check text-warning pr-2"></i> 
                                            <b>
                                            You don't really know the exact value of your stock, when they're expiring or when they're in low quantity unless you keep going round and round your entire shop every single day…
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-check text-warning pr-2"></i> 
                                            <b>
                                            Can't travel or take some days off because you're afraid of your sales reps stealing from you - and you won't find out…
                                            </b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p>
                                Right?
                            </p>
                            <h4 className='text-center'>
                                That's Where A Tool Like "Smart Business Manager" Comes In…
                            </h4>
                            <p className='text-white text-center'>
                                Smart business manager helps you to  easily manage your stocks, track your finances and manage ALL the aspects of your business from ANYWHERE with peace of mind…
                            </p>
                        </div>
                        <div className='col-md-12 p-4 m-0'>
                            <h4 className='text-center text-info'>
                                The Smart Business Manager is The Secret To Running Your Business Without ALL The Stress…
                            </h4>
                            <p>
                                It's the difference between business owners who struggle to keep their business 
                                together…<b>and those who thrive!</b>
                            </p>
                        </div>
                        <div className='col-md-6 p-4'>
                            <h5 className='text-center card-header'>
                                Without The Smart Business Manager
                            </h5>
                            <div
                                animation-type="fadeInRight" 
                                className="animated-item animated fadeInRight card bg-light p-3">
                                <ul className="text-left">
                                    <li className="mb-2">
                                        <i className="fas fa-warning text-warning pr-2"></i> 
                                        <b>
                                            Slow sales process
                                        </b>
                                    </li>
                                    <li className="mb-2">
                                        <i className="fas fa-warning text-warning pr-2"></i> 
                                        <b>
                                            Always suspecting your staff
                                        </b>
                                    </li>
                                    <li className="mb-2">
                                        <i className="fas fa-warning text-warning pr-2"></i> 
                                        <b>
                                            Can't track your income & expenses
                                        </b>
                                    </li>
                                    <li className="mb-2">
                                        <i className="fas fa-warning text-warning pr-2"></i> 
                                        <b>
                                            Stress
                                        </b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6 p-4'>
                            <h5 className='text-center card-header'>
                                With Smart Business Manager
                            </h5>
                            <div
                                animation-type="fadeInRight" 
                                className="animated-item animated fadeInRight card bg-dark text-light pb-0 p-3">
                                <ul className="text-left">
                                    <li className="mb-2">
                                        <i className="fas fa-check text-warning pr-2"></i> 
                                        <b>
                                            Fast & Easy sales process
                                        </b>
                                    </li>
                                    <li className="mb-2">
                                        <i className="fas fa-check text-warning pr-2"></i> 
                                        <b>
                                            Impossible for your staff to steal from you
                                        </b>
                                    </li>
                                    <li className="mb-2">
                                        <i className="fas fa-check text-warning pr-2"></i> 
                                        <b>
                                            Track exactly how much your business is making and worth…
                                        </b>
                                    </li>
                                    <li className="mb-2">
                                        <i className="fas fa-check text-warning pr-2"></i> 
                                        <b>
                                            Opposite of stress.
                                        </b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className='col-md-12 p-4'>
                            <h5 className='text-center text-danger'>
                                Do You Like Feeling The Opposite of Stress?
                            </h5>
                            <h4 className='text-center text-success'>
                                Then Try Smart Business Manager Today!
                            </h4>
                            <CallToActionComponent></CallToActionComponent>
                        </div>
                        <div className='p-4 bg-primary text-center'>
                            <h4 className='text-light'>
                                NOW, You Can Track, Control And Optimise Your Entire Business Operations…
                            </h4>
                            <h3>All in One Place!</h3>
                            <h5>Daily Business Operations Made Easy</h5>
                            <p>
                                Now, you can easily execute your daily business tasks without all the stress usually involved…
                            </p>
                            <h5>With Smart business manager, you can…</h5>
                            <ul className='text-light text-left'>
                                <li>
                                    <i className='fa fa-check pr-2 text-white'></i>
                                    Record sales and purchases in 3 seconds flat with multiple prices, from multiple payment accounts and debt management from customers…
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-white'></i>
                                    Easily monitor stock and cash transfer between different branches…
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-white'></i>
                                    Real time overview of your business stock value, sales, expenses plus creditors/debtors list all in one screen.
                                </li>
                            </ul>
                        </div>
                        <div className='p-4 bg-light text-center'>
                            <h4 className='text-dark'>
                                Easy Stock Taking
                            </h4>
                            <p>
                                Taking accurate stock of your products used to be a big hassle. Sometimes, you'll have to close down your shop for weeks and lose money just to count them…
                            </p>
                            <h5>With Smart Business Manager, you can</h5>
                            <ul className='text-secondary text-left'>
                                <li>
                                    <i className='fa fa-check pr-2 text-dark'></i>
                                    Count your entire stock in minutes while you sell…
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-dark'></i>
                                    Easily adjust the price, quantity of your stock
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-dark'></i>
                                    Transfer your stock from one branch to another
                                </li>
                            </ul>
                            <hr />
                            <h4>Cashflow System</h4>
                            <p>
                                Now you no longer have to worry about where all your money is going…
                            </p>
                            <h5>With our Inbuilt Cashflow System, you can</h5>
                            <ul className='text-primary text-left'>
                                <li>
                                    <i className='fa fa-arrow-right pr-2 text-dark'></i>
                                    See the exact amount of money that comes into your business daily and from which channel (pos, cash, transfer)
                                </li>
                                <li>
                                    <i className='fa fa-arrow-right pr-2 text-dark'></i>
                                    Track the amount of money used for business and  miscellaneous expenses
                                </li>
                            </ul>
                        </div>
                        <div className='p-4 bg-primary text-center'>
                            <h4 className='text-light'>
                                Inventory Management
                            </h4>
                            <p>
                                If you have a huge stock, managing and keeping track of them can be a huge headache…
                            </p>
                            <h5>But with smart business manager, you can</h5>
                            <ul className='text-light text-left'>
                                <li>
                                    <i className='fa fa-check pr-2 text-white'></i>
                                    Automatically track the damaged, low, out of stock, dormant products you have so you can optimise your business properly.
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-white'></i>
                                    Monitor the movement of your products. See the fast moving products you have as well as detailed reports on how much they contribute to your business…
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-white'></i>
                                    Discover the exact value of your available products in one click!
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-12 p-4 bg-white'>
                            <h4>Advanced Financial Reporting</h4>
                            <p>Now, you no longer need to flip through your big ledgers with a pen in hand and calculator on the table like before…</p>
                            <h5>With our advanced financial reporting system, you can</h5>
                            <ul className='text-info text-left'>
                                <li>
                                    <i className='fa fa-check pr-2 text-success'></i>
                                    See exactly how much you're making per day & how much you're spending in expenses (sales and purchases) as well as calculate your entire profit/loss within a specified period of time in ONE click
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-success'></i>
                                    Have a detailed report of who you're owing/who's owing you…as well as the due date for each payment…
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-success'></i>
                                    Get a detailed report on how exactly money is flowing into your business (cash, cheque, transfer, pos)
                                </li>
                            </ul>
                        </div>
                        <hr />
                        <div className='col-md-12 p-4 bg-light'>
                            <h4>Smart Income Sheet…</h4>
                            <p>We believe that the grand scheme of running a successful business is to be able to hand it over to the future generations…</p>
                            <h5>And with the smart business manager,</h5>
                            <ul className='text-info text-left'>
                                <li>
                                    <i className='fa fa-check pr-2 text-success'></i>
                                    You can actually see a detailed analysis of how much your business is going to be worth in the next 2, 5, 10 years…
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-success'></i>
                                    Get advice from renowned experts on how you can grow your business even more…
                                </li>
                                <li>
                                    <i className='fa fa-check pr-2 text-success'></i>
                                    Get a detailed report on how exactly money is flowing into your business (cash, cheque, transfer, pos)
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-12 p-4 text-left m-4 bg-white'>
                            <h4>Smart Business Manager Gives You Everything To Run Your Business - The Smart Way</h4>
                            <p><b>Easy to Navigate Sales System </b>- Record sales and purchases in 3 seconds flat</p>
                            <p><b>Staff/User account management </b>- Track your staff's input for adequate accountability</p>
                            <p><b>Cash account management </b>- Track exactly where major inflows come from</p>
                            <p><b>Product Importation System </b>- Easily import your stock into the software using this system and start selling!</p>
                            <p><b>Inventory Management system </b>- Manage your stock in real time</p>
                            <p><b>Stock Taking </b>- Know exactly how many stock you have as well as the value of them…</p>
                            <p><b>Advanced Financial Reporting system </b>- Track all the money that comes in and goes out of 
                                your business on a daily basis and get detailed reports on your finances
                            </p>
                            <p><b>Smart Income Sheet </b>- Be able to predict where your business is going in the future</p>
                            <p><b>Backed up database </b>- You can never lose your data because everything is backed up for you in our secure database</p>
                            <p><b>24/7  technical support system </b>- Never get confused as our support team is on standby to help you with whatever you need…</p>
                            <p><b>Invoice & receipts </b>- Issue professional invoices and receipts automatically</p>
                            <p><b>Unlimited transactions </b>- Use as you like</p>
                            <p><b>Online/ Offline </b>- You can use the online or offline version depending on your preference</p>
                            <p><b>FREE Updates & Upgrades </b>- Get automatic updates anytime they're ready at no extra costs..</p>
                            <p><b>User Bonuses </b>- Access to our loyalty and reward program for business owners who actively use the smart business manager…</p>
                        </div>
                        <div className='col-md-6'>
                            <h4 className='text-center card-header'>
                                Built for Businesses like Yours
                            </h4>
                            <div
                                animation-type="fadeInRight" 
                                className="animated-item animated fadeInRight">
                                <div className="card bg-light p-5">
                                    <ul className="text-left">
                                        <li className="mb-2">
                                            <i className="fas fa-star text-warning pr-2"></i> 
                                            <b>
                                                Restaurants
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-star text-warning pr-2"></i> 
                                            <b>
                                                Services
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-star text-warning pr-2"></i> 
                                            <b>
                                                Supermarkets
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-star text-warning pr-2"></i> 
                                            <b>
                                                Retail stores
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-star text-warning pr-2"></i> 
                                            <b>
                                                Hospitality
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-star text-warning pr-2"></i> 
                                            <b>
                                                Schools
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-star text-warning pr-2"></i> 
                                            <b>
                                                Wholesale & Distributors
                                            </b>
                                        </li>
                                        <li className="mb-2">
                                            <i className="fas fa-star text-warning pr-2"></i> 
                                            <b>
                                                Pharmacies
                                            </b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 text-center'>
                            {/* <a href="../Software/Smart Business Manager.zip" */}
                            <a href="../Download-SmartBusiness-Manager"
                                className=''>
                                <img src={download1} className="download-btn"
                                    alt={"Download "+appConfig.AppName+" Now"} />
                            </a>
                            <SoftwareCarosel></SoftwareCarosel>
                        </div>
                    </div>
                    
                    <div className="col-md-12 mt-5 mb-5 text-center">
                        <h3>Trusted by Top Brands & Businesses</h3>
                    </div>
                    <ReviewsComponent></ReviewsComponent>
                    
                    <CallToActionComponent></CallToActionComponent>
                    
                    <div className=''>
                        <FAQComponent></FAQComponent>
                    </div>
                    <hr />
                    {/* <div className='bg-white'>
                        <SupportComponent></SupportComponent>
                    </div> */}
                </div>
                <SubscribeComponent></SubscribeComponent>
            </div>
        );
    }
}
 
export default Home;

