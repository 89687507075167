import React, {Component} from "react";

export const AboutComponent = () => {

    return ( 
        <div className="container justify-content-center d-flex mt-5 mb-5">
            <div className="col-md-8">
                <div id='About' animation-type='fadeInRight' 
                    className="animated-item animated fadeInRight card-header bg-light">
                    <h4 className="text-primary mt-4 ">
                        <u>An All-In-One Software Solution For Your Business</u>
                    </h4>
                    <p hidden>
                        <b>Motto :</b> For peace of mind Investment.
                    </p>
                    <p>
                        We present to you an advanced all-in-one software solutions for your business.
                    </p>
                    <p>
                        Smart Business Manager is a complete accounting + inventory 
                        management software designed specially for Windows computers. 
                        Create estimates and invoices, receipts & payments, record expenses, 
                        bills and purchases, Banking/Journal vouchers and view over 35+ financial reports.
                        With it, creating professional invoices for your business becomes easy.
                    </p>
                    <p>
                        Smart Business Manager's powerful inventory management allows for total inventory control.
                        Keep track of inventory, Create stock, Adjust stock, Manage stock between offices/branches.
                        You can also manage bank accounts, creditors, debtors and expenses.
                        Insightful inventory reports help you track inventory easily.
                    </p>
                </div>
            </div>
        </div>
     );
}