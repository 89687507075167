import React from 'react'
import { Component } from 'react'
import { Route, Navigate } from 'react-router-dom'
import { Loader } from '../loading'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;
        var link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`

        // console.log(redirectUrl)
        // console.log(returnUrl)
        // console.log(link.href)

        if (!ready) {
            return (<div>
                <p className='text-center text-primary'>Authorizing... Please wait.</p>
                <Loader />
            </div>);
        } else {
            const { component: Component, ...rest } = this.props;
            if (authenticated) {
                return <Component {...rest} />
            } else {
                sessionStorage.setItem("redirectUrl", returnUrl);
                return <Navigate to={redirectUrl} />
            }
            // return <Route {...rest}
            //     render={(props) => {
            //     }} />
        }
    }

    async populateAuthenticationState() {
        const _authenticated = await authService.isAuthenticated();
        // console.log("Auth returned: " + _authenticated)
        // console.log(this.state.ready)
        this.setState({ ready: true, authenticated: _authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
