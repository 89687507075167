import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import { LoginComponent } from '../Account/login';
import { LayoutBox } from '../Layout_Small_Box';
import { RegisterComponent } from '../Account/register';
import { Loader } from '../loading';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined
        };
    }

    componentDidMount() {
    }

    render() {
        const action = this.props.action;
        const { message } = this.state;

        if (!!message) {
            return <div>{message}</div>
        } else {
            switch (action) {
                case LoginActions.Login:
                    return (<LayoutBox title='Login Form' 
                        subTitle='Fill the form to proceed'>
                            <LoginComponent />
                        </LayoutBox>
                    );
                case LoginActions.LoginCallback:
                    return (<Loader />);
                case LoginActions.Profile:
                    return (<Loader />);
                case LoginActions.Register:
                    return (<LayoutBox title='Register Form' 
                        subTitle='Fill the form to proceed'>
                            <RegisterComponent />
                        </LayoutBox>
                    );
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
    }

}
