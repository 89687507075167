import property1 from '../images/bk/slide-1.png';
import property2 from '../images/bk/slide-2.png';
import property3 from '../images/bk/slide-3.png';
import property4 from '../images/bk/slide-4.png';
import React, { Component } from 'react';

import { Carousel, Row } from 'react-bootstrap';
import appConfig from '../AppConfig';

export default class SoftwareCarosel extends Component {
    
    state = { 
        propertyStyle: {
            'width': '100%',
            'maxWidth': '100%',
            'pointer-events': 'none',
        },
        Carousel: {
            'overflow': 'hidden',
        }, carouselIndex: 0,
    } 

    componentDidMount(){
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({carouselIndex: selectedIndex});
    };

    render() { 
        return (
            <Carousel fade interval={1500} variant="dark" 
                className='carousel slide carousel-fade carousel-dark row justify-content-center'
                activeIndex={this.state.carouselIndex} 
                onSelect={this.handleSelect}
                >
                <Carousel.Item style={this.state.Carousel}>
                    <img src={property1} className="d-block" alt="..." style={this.state.propertyStyle} />
                    <Carousel.Caption className='d-flex justify-content-center '>
                        <div className="Caption" hidden>
                            <p className='small'>Live Dashboard with real-time graph.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={this.state.Carousel}>
                    <img src={property2} className="d-block" alt="..." style={this.state.propertyStyle}/>
                    <Carousel.Caption className='d-flex justify-content-center '>
                        <div className="Caption" hidden>
                            <p className='small'>Responsive UI intended for quick sales.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={this.state.Carousel}>
                    <img src={property3} className="d-block" alt="..." style={this.state.propertyStyle} />
                    <Carousel.Caption className='d-flex justify-content-center '>
                        <div className="Caption" hidden>
                            <p className='small'>Role based authorisation system.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={this.state.Carousel}>
                    <img src={property4} className="d-block" alt="..." style={this.state.propertyStyle} />
                    <Carousel.Caption className='d-flex justify-content-center '>
                        <div className="Caption" hidden>
                            <p className='small'>Advanced reporting, invoicing and billing system.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        );
    }
}

